export function kftApiRequest(url: string, options = {}) {
  const config = useRuntimeConfig()
  const { apiBaseURL } = config.public

  const defaultOptions = {
    baseURL: apiBaseURL,
    method: 'POST',
    // cache request
    key: url,
    headers: {
      'Content-Type': 'application/json',
    },
  }

  const fetchOptions = { ...defaultOptions, ...options }

  return $fetch(url, fetchOptions)
}
